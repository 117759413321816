import neoSwitch from "@/components/toggle-switch";
import {mapGetters} from "vuex";
import Loader from "@/components/loader";

export default {
    components: {
        neoSwitch,
        "neo-loader": Loader,
    },
    props: {
        showHistory: {
            type: Boolean,
            default: false,
        },
        result: {
            type: Object,
            default: null,
        },
        isOldVersion: {
            type: Boolean,
            default: false,
        },
        isLastVersion: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    methods: {
        async showMonitoringHistory() {
            this.$emit("showMonitoringHistory");
        },
    },
    computed: {
        ...mapGetters([]),
        isMonitoringTab() {
            return this.$route.name === "monitoring";
        },
        getSourceTreeHeight() {
            if (this.isOldVersion) {
                if (this.isLastVersion) return "height: 100%";
                else return "height: calc(100% + 16px)";
            } else return "height: calc(100% - 20px)";
        },
    },
};
